/**
 * External dependencies
 */
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

/**
 * Internal dependencies
 */
import Carousel from "../carousel/carousel"
import VideoPlayer from "../video-player/video-player"
import "./styles/_index.scss"
import PDF from "./placeholder-pdf.pdf"

const CustomModal = ({ modalShow, closeModal, modalContent, config = {} }) => (
  <Modal
    dialogClassName="regular"
    show={modalShow}
    onEscapeKeyDown={closeModal}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    fullscreen={true}
    {...config}
  >
    <Modal.Body>
      {modalContent}

      <button className="modal__close-btn clear-btn" onClick={closeModal}>
        <i className="icon icon--close" />
      </button>
    </Modal.Body>
  </Modal>
)

const ModalGallery = ({ gallery, index }) => {
  const [play, setPlaying] = useState(false)
  const data = Object.values(gallery).map(x => {
    if (x.type === "video") {
      return (
        <div className="modal__gallery-slide h-100">
          <VideoPlayer src={x.value} fullVh={false} autoPlay={play} />
        </div>
      )
    }
    return (
      <div className="modal__gallery-slide h-100">
        <GatsbyImage image={x.value} alt="property" />
      </div>
    )
  })

  return (
    <div className="modal__gallery">
      <Carousel
        data={data}
        index={index}
        controllers={{ buttons: true, progress: true }}
        gallery={gallery}
        setPlaying={setPlaying}
      />
    </div>
  )
}

const ModalVideo = ({ src, autoplay }) => (
  <div className="modal__video">
    <VideoPlayer src={src} autoplay={autoplay} fullVh={false} />
  </div>
)

const ModalImage = ({ image, alt = "property" }) => (
  <div className="modal__image">
    <GatsbyImage image={image} alt={alt} />
  </div>
)

const ModalPdf = ({ src = PDF, title = "floorplan" }) => (
  <div className="modal__pdf">
    <iframe src={src} title={title} height="100%" width="100%" />
  </div>
)

CustomModal.Gallery = ModalGallery
CustomModal.Video = ModalVideo
CustomModal.Image = ModalImage
CustomModal.Pdf = ModalPdf

export default CustomModal
