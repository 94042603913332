/**
 * External dependencies
 */
import React from "react"
import ReactPlayer from "react-player"

const VideoPlayer = ({ src, fullVh = true, autoPlay = true }) => (
  <ReactPlayer
    frameBorder="0"
    allowFullScreen
    url={src}
    modestbranding="1"
    controls={true}
    autoPlay={autoPlay}
    muted={false}
    playsinline={autoPlay}
    playing={autoPlay}
    width="100%"
    height={fullVh ? "100vh" : "100%"}
    previewTabIndex={0}
    light={false}
  />
)

export default VideoPlayer
